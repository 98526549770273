import React from 'react';
import { useBlogContext } from '../../hooks/contextHooks';
import Card from '../Card/index';
import { HomeHeader, HomeInner, HomeSubHeader, HomeWrapper } from './styled';

const Home = () => {
  const { blogList } = useBlogContext();
  return (
    <HomeWrapper>
      <HomeInner>
        <HomeHeader
          gridC="1 / span 5"
          gridR="1 / span 3"
          tabletGridC="2 / span 10"
          tabletGridR="1 / span 1"
        >
          Hello I'm Stefan
        </HomeHeader>
        <Card
          title="Socials"
          gridC="8 / span 5"
          gridR="1 / span 3"
          tabletGridC="2 / span 4"
          tabletGridR="2 / span 1"
          links={{
            Socials: [
              {
                name: 'LinkedIn',
                link: 'https://www.linkedin.com/in/stefan-wright8891',
              },
              {
                name: 'Twitter',
                link: 'https://twitter.com/stefanwright1988',
              },
              {
                name: 'Instagram - Personal',
                link: 'https://www.instagram.com/stefwright1988/',
              },
            ],
          }}
        ></Card>
        <HomeSubHeader
          gridC="8 / span 5"
          gridR="4 / span 3"
          tabletGridC="2 / span 10"
          tabletGridR="3 / span 1"
        >
          I am a JavaScript Developer
        </HomeSubHeader>
        <Card
          title="Techy Socials"
          gridC="1 / span 5"
          gridR="4 / span 3"
          tabletGridC="8 / span 4"
          tabletGridR="2 / span 1"
          links={{
            Socials: [
              {
                name: 'GitHub',
                link: 'https://github.com/stefanwright1988',
              },
              {
                name: 'DevTo',
                link: 'https://dev.to/stefanwrightcodes',
              },
              {
                name: 'Instagram - Coding',
                link: 'https://www.instagram.com/stefan.wright.codes',
              },
            ],
          }}
        ></Card>
        <HomeHeader
          gridC="1 / span 5"
          gridR="7 / span 2"
          tabletGridC="2 / span 10"
          tabletGridR="4 / span 1"
        >
          Latest blog post
        </HomeHeader>
        <Card
          gridC="8 / span 5"
          gridR="7 / span 2"
          tabletGridC="4 / span 6"
          tabletGridR="5 / span 1"
          title={blogList[0].title}
          links={{
            Right: {
              name: 'Read More',
              link: blogList[0].slug,
              type: 'internal',
            },
            Left: {
              name: blogList[0].source,
              link: blogList[0].url,
              type: 'external',
            },
          }}
        >
          {blogList[0].description}
        </Card>
      </HomeInner>
    </HomeWrapper>
  );
};

export default Home;

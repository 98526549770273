import styled, { keyframes } from 'styled-components';

const keySpin = keyframes`
0%{ 
transform: rotate(0deg) scale(0);
}
50%{
transform: rotate(360deg) scale(5);
100%{
transform: rotate(0deg) scale(1);
}
`;

const FullScreenLoaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: ${window.matchMedia('(prefers-color-scheme: dark)')
    ? '#1A1A37'
    : '#F7F8F9'};
`;

const FullScreenLoaderLogo = styled.div`
  animation-name: ${keySpin};
  animation-duration: 6s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  z-index: 1001;
`;

export { FullScreenLoaderContainer, FullScreenLoaderLogo };

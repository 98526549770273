import { BrowserRouter } from 'react-router-dom';
import { FullScreenLoader } from './components/FullScreenLoader';
import Header from './components/Header';
import { Main } from './components/Main';
import { useAppContext } from './hooks/contextHooks';

function App() {
  const { isAppLoading } = useAppContext();
  if (isAppLoading) {
    return <FullScreenLoader />;
  }
  return (
    <BrowserRouter>
      <Header />
      <Main />
    </BrowserRouter>
  );
}

export default App;

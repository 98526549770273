import styled from 'styled-components';
import { desktopMin, tabletMin } from '../../../constants/constants';
import { IDropdownProps } from './index';

const DropDownContainer = styled('div')<IDropdownProps>`
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
`;

const DropDownHeader = styled('div')`
  margin-bottom: 0.5em;
  padding: 0.4em 2em 0.4em 0.5em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border: 2px solid var(--cardBorder);
  color: var(--cardMainText);
  background: var(--cardMainBackground);
`;

const DropDownListContainer = styled('div')``;

const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  padding-left: 0.5em;
  background: var(--cardMainBackground);
  border: 2px solid var(--cardBorder);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  color: var(--cardMainText);
  &:first-child {
    padding-top: 0.5em;
  }
`;

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
  display: flex;
  list-style: none;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  & svg {
    margin-right: 10px;
  }
`;

export {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
};

import { Route, Routes } from "react-router-dom";
import { H1 } from "../../style/styled";
import AboutMe from "../AboutMe";
import AuthGate from "../AuthGate";
import BlogList from "../Blog/List";
import BlogPost from "../Blog/Post";
import Home from "../Home";
import ProfilePage from "../Profile";
import Work from "../Work";
import { MainWrapper } from "./styled";

const Main = () => {
  return (
    <>
      <MainWrapper>
        <Routes>
          <Route path="/login" element={<AuthGate />} />
          <Route path="/" element={<Home />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/filtered/:blogFilter" element={<BlogList />} />
          <Route path="/blog/:blogSlug" element={<BlogPost />} />
          <Route path="/work" element={<Work />} />
          <Route
            path="/games"
            element={
              <>
                <h1>Games</h1>
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <h1>Contact</h1>
              </>
            }
          />
          <Route path="/profilePage" element={<ProfilePage />} />
          <Route
            path="*"
            element={
              <>
                <H1>No Match found</H1>
              </>
            }
          />
        </Routes>
      </MainWrapper>
    </>
  );
};

export { Main };

import { useParams } from 'react-router';
import { useBlogContext } from '../../../hooks/contextHooks';
import Dropdown from '../Dropdown';
import { BlogPaginatedItems } from '../Pagination';
import {
  BlogListHeader,
  BlogListInner,
  BlogListNoPosts,
  BlogListPostContainer,
  BlogListWrapper,
} from './styled';

const BlogList = () => {
  const { blogList } = useBlogContext();
  const { blogFilter } = useParams();
  let noPosts: boolean = false;
  let blogListData: any[] = [];
  blogListData = blogList;
  if (blogFilter) {
    blogListData = blogList.filter((post) => {
      return post.tag_list.includes(blogFilter);
    });
    if (blogListData.length === 0) {
      blogListData = blogList;
      noPosts = true;
    }
  }

  return (
    <BlogListWrapper>
      <BlogListInner>
        <BlogListHeader
          gridC="1 / span 12"
          gridR="1 / span 1"
          tabletGridC="1 / span 12"
          tabletGridR="1 / span 1"
        >
          Blog Posts
        </BlogListHeader>
        <Dropdown
          gridC="1 / span 12"
          gridR="2 / span 1"
          tabletGridC="1 / span 12"
          tabletGridR="2 / span 1"
        />
        {noPosts && (
          <BlogListNoPosts>
            No posts matched '{blogFilter}'. Here are all the articles instead
          </BlogListNoPosts>
        )}
        <BlogPaginatedItems
          itemsPerPage={6}
          blogList={blogListData}
          noPosts={noPosts}
        />
      </BlogListInner>
    </BlogListWrapper>
  );
};

export default BlogList;

import styled from "styled-components";
import { desktopMin, tabletMin } from "../../constants/constants";

const WorkWrapper = styled.div`
  margin: 0px auto;
  width: 100%;
  max-width: 1400px;
  min-height: calc(100vh - 120px);
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  @media screen and ${desktopMin} {
    justify-content: center;
  }
`;

const WorkInner = styled.div`
  display: grid;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  @media screen and ${tabletMin} {
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-auto-rows: fit-content(5rem);
    column-gap: 1rem;
  }
  @media screen and ${desktopMin} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const WorkCardInner = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 30px 30px 30px auto;
  grid-gap: 0.5rem;
  @media screen and ${tabletMin} {
    display: grid;
  }
`;

interface WorkCardImageProps {
  src: string;
  themeType: string;
}

const WorkCardImage = styled.div<WorkCardImageProps>`
  background-image: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  grid-column: 1 / span 3;
  grid-row: 1 / span 4;
  background-color: ${(props) =>
    props.themeType === "light" ? "var(--cardBorder)" : "transparent"};
  border-radius: 40px;
  aspect-ratio: 1;
  max-height: 140px;
  justify-self: center;
`;

const WorkCardJobTitle = styled.div`
  grid-row: 1 / span 1;
  grid-column: 4 / span 9;
`;

const WorkCardCompany = styled.div`
  grid-row: 2 / span 1;
  grid-column: 4 / span 9;
`;

const WorkCardStarted = styled.div`
  grid-row: 3 / span 1;
  grid-column: 4 / span 9;
`;

const WorkCardLeft = styled.div`
  grid-row: 4 / span 1;
  grid-column: 4 / span 9;
`;

const WorkCardDetails = styled.div`
  grid-row: 5 / span 1;
  grid-column: 1 / span 12;
`;

const WorkCardP = styled.p`
  margin: 0.5rem;
`;

export {
  WorkWrapper,
  WorkInner,
  WorkCardInner,
  WorkCardImage,
  WorkCardJobTitle,
  WorkCardCompany,
  WorkCardStarted,
  WorkCardLeft,
  WorkCardDetails,
  WorkCardP,
};

import styled from 'styled-components';
import { desktopMin, tabletMin } from '../../../constants/constants';
import { H1 } from '../../../style/styled';
import { CardContent, CardWrapper } from '../../Card/styled';
interface BlogListProps {
  gridC: string;
  gridR: string;
  tabletGridC: string;
  tabletGridR: string;
}

const BlogListWrapper = styled.section`
  padding-top: 10px;
  @media screen and ${desktopMin} {
    padding-top: 2rem;
    min-height: calc(100vh - 100px);
  }
`;

const BlogListInner = styled.div`
  display: grid;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-items: center;
  grid-gap: 2rem;
  @media screen and ${tabletMin} {
    grid-template-rows: minmax(8rem, auto) 1fr;
    grid-template-columns: repeat(12, [col-start] 1fr);
    padding-left: 0;
    padding-right: 0;
  }
`;

const BlogListHeader = styled(H1)<BlogListProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px 0px;
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
`;

const BlogListPostContainer = styled.div<BlogListProps>`
  display: grid;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-gap: 2rem;
  @media screen and ${tabletMin} {
    grid-template-rows: minmax(40px, auto) 1fr;
    //grid-template-columns: repeat(3, [col-start] 1fr);
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    grid-gap: 2rem;
    padding-left: 0;
    padding-right: 0;
  }
  & ${CardWrapper} {
    height: 100%;
    margin: 0;
  }
  & ${CardWrapper} > ${CardContent} {
    flex-grow: 1;
  }
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
`;

const BlogListNoPosts = styled.p`
  grid-column: 1 / span 12;
  grid-row: 1;
`;

const BlogListPostTagsList = styled.ul`
  margin-top: 0.5rem;
`;

const BlogListPostTagsListItem = styled.li`
  display: flex;
  list-style: none;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  & svg {
    margin-right: 10px;
  }
`;

export {
  BlogListInner,
  BlogListWrapper,
  BlogListHeader,
  BlogListPostContainer,
  BlogListPostTagsList,
  BlogListPostTagsListItem,
  BlogListNoPosts,
};

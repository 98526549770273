const MyLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="56px"
      height="56px"
      viewBox="0 0 870.000000 834.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,834.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="currentColor"
      >
        <path d="M4020 8209 c-534 -53 -1006 -186 -1459 -413 -408 -204 -810 -499 -1116 -817 l-90 -94 667 -3 667 -2 50 29 c360 213 751 346 1191 408 176 24 663 24 837 0 427 -60 783 -178 1148 -382 l99 -55 666 2 665 3 -145 146 c-615 618 -1416 1022 -2280 1149 -257 38 -674 51 -900 29z m810 -273 c520 -69 1006 -234 1445 -489 75 -44 339 -219 368 -245 9 -8 17 -26 17 -39 l0 -23 -303 0 -304 0 -102 55 c-565 303 -1239 446 -1858 394 -383 -32 -783 -134 -1123 -287 -80 -36 -154 -71 -165 -77 -11 -7 -51 -29 -88 -49 l-69 -36 -305 0 -304 0 3 28 c3 30 78 86 308 230 466 293 1024 485 1570 541 58 6 119 13 135 15 82 11 658 -3 775 -18z" />
        <path d="M902 6273 c-26 -37 -157 -272 -175 -314 -8 -19 -33 -73 -56 -121 -161 -342 -284 -779 -337 -1198 -24 -196 -30 -611 -10 -809 9 -90 19 -168 22 -172 3 -5 598 -9 1330 -9 l1324 0 0 -355 0 -355 -1245 0 c-685 0 -1245 -2 -1245 -5 0 -22 99 -279 150 -390 18 -38 43 -94 57 -123 31 -68 155 -293 183 -332 l21 -30 1567 2 1567 3 0 1320 0 1320 -1409 3 -1409 2 7 62 c11 109 85 375 153 555 l36 93 1311 2 1311 3 0 435 0 435 -1567 3 -1567 2 -19 -27z m2888 -413 l0 -170 -1259 0 c-995 0 -1261 -3 -1268 -12 -20 -27 -123 -290 -171 -438 -28 -85 -64 -211 -81 -280 -28 -119 -91 -467 -91 -503 0 -16 91 -17 1435 -17 l1435 0 0 -1055 0 -1055 -1368 0 -1369 0 -76 154 c-43 84 -77 160 -77 169 0 16 93 17 1183 19 l1182 3 0 620 0 620 -1340 5 -1340 5 1 295 c0 245 4 323 22 459 57 425 179 819 374 1209 l72 142 1368 0 1368 0 0 -170z" />
        <path d="M4642 4183 l3 -2118 525 -3 525 -2 445 445 c350 350 450 445 470 445 20 0 120 -95 470 -445 l445 -445 127 0 127 0 29 43 c16 23 56 92 89 152 222 407 373 851 447 1317 64 402 60 867 -9 1271 -75 437 -231 887 -438 1262 -33 61 -73 129 -89 153 l-29 42 -535 0 -535 0 53 -62 c307 -362 540 -814 658 -1278 33 -128 68 -358 81 -520 14 -185 6 -526 -16 -685 -8 -60 -15 -111 -15 -112 0 -2 -10 -3 -22 -3 -17 0 -136 113 -430 407 l-408 408 -428 -428 c-355 -355 -432 -427 -455 -427 l-27 0 0 1350 0 1350 -530 0 -530 0 2 -2117z m788 290 l0 -1558 583 583 c320 320 589 582 597 582 8 0 243 -228 522 -507 455 -454 507 -504 512 -482 24 101 116 712 125 825 39 498 -29 982 -205 1455 -64 174 -164 385 -253 536 -34 56 -61 107 -61 113 0 6 67 10 199 10 l199 0 80 -162 c213 -433 328 -833 377 -1318 23 -224 16 -668 -14 -880 -62 -434 -169 -783 -358 -1167 -59 -119 -90 -173 -102 -173 -9 0 -242 226 -518 502 l-503 503 -503 -503 -502 -502 -348 0 -347 0 0 1850 0 1850 260 0 260 0 0 -1557z" />
        <path d="M1379 1452 c56 -80 367 -360 556 -501 706 -529 1544 -811 2415 -811 866 0 1683 272 2400 798 151 111 385 316 505 444 l87 93 -665 3 -666 2 -48 -28 c-365 -214 -752 -347 -1196 -409 -173 -24 -661 -24 -835 0 -441 62 -832 196 -1195 409 l-48 28 -665 0 -665 0 20 -28z m1363 -288 c341 -189 756 -319 1233 -386 121 -17 630 -17 750 0 386 55 690 137 990 267 109 47 281 134 320 161 15 11 80 14 318 14 269 0 298 -2 304 -17 8 -21 -35 -58 -182 -156 -504 -338 -1015 -533 -1624 -619 -136 -20 -199 -23 -491 -23 -220 0 -369 4 -435 13 -439 58 -768 148 -1136 314 -200 90 -353 175 -561 315 -169 113 -194 134 -185 158 6 13 45 15 302 15 l295 0 102 -56z" />
      </g>
    </svg>
  );
};

export default MyLogo;

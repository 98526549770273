import { createContext, FC, useEffect, useState } from 'react';
import { User, onAuthStateChanged } from '@firebase/auth';
import { auth } from '../firebase/firebase';
import { useAppContext } from '../hooks/contextHooks';

interface IAuthContext {
  user: User | null;
  authenticated: boolean;
  setUser: any;
  loadingAuthState: boolean;
}
export const AuthContext = createContext<Partial<IAuthContext>>({});

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState(null as User | null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  const { setIsAuthStatusLoading } = useAppContext();
  useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      setUser(user);
      setLoadingAuthState(false);
      setIsAuthStatusLoading(false);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { useParams } from 'react-router';
import {
  useAppContext,
  useBlogContext,
  useThemeContext,
} from '../../../hooks/contextHooks';
import Card from '../../Card';
import {
  BlogCode,
  BlogPostContent,
  BlogPostInner,
  BlogPostP,
  BlogPostPreDiv,
  BlogPostUL,
  BlogPostWrapper,
} from './styled';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  a11yDark,
  a11yLight,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const BlogPost = () => {
  const { themeType } = useThemeContext();
  const { isAppLoading } = useAppContext();
  const { blogSlug } = useParams();
  const { blogList } = useBlogContext();
  if (isAppLoading) {
    return null;
  }
  const syntaxHighlightTheme = themeType === 'dark' ? a11yDark : a11yLight;
  const postToDisplay = blogList.filter((post) => post.slug === blogSlug);
  // Look for the post before the current one, because posts are sorted from newest first
  //const nextPost = blogList.filter(
  //  (post, index) => index === blogList.indexOf(postToDisplay[0]) - 1
  //);
  // Look for the post after the current one, because posts are sorted from newest first
  //const prevPost = blogList.filter(
  //  (post, index) => index === blogList.indexOf(postToDisplay[0]) + 1
  //);

  let postContent = postToDisplay[0].body_markdown;
  postContent = postContent.replace(
    /({%)+(\syoutube\s)([\s\S]*[^\s%}])(\s%})+/gi,
    `<div><iframe width="560" height="315" src="https://www.youtube.com/embed/$3" frameborder="0" allowfullscreen></iframe></div>`
  );
  postContent = postContent.replace(
    /(\[)([\s\S]*[^\]])(\])([\n\r])(\(http[\s\S]*[^)]\))/gi,
    '$1$2$3$5'
  );

  return (
    <BlogPostWrapper>
      <BlogPostInner>
        <Card title={postToDisplay[0].title}>
          <BlogPostContent>
            <ReactMarkdown
              children={postContent}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline && match ? (
                    <BlogPostPreDiv>
                      <Card>
                        <SyntaxHighlighter
                          style={syntaxHighlightTheme}
                          language={match[1]}
                          lineProps={{
                            style: {
                              whiteSpace: 'pre-wrap',
                            },
                          }}
                          wrapLines={true}
                          {...props}
                        >
                          {String(children)
                            .replace(/\n$/, '')
                            .replace(/,(?=\S)/, ', ')
                            .replace(/\/(?=\S)/g, '/ ')}
                        </SyntaxHighlighter>
                      </Card>
                    </BlogPostPreDiv>
                  ) : !inline ? (
                    <BlogPostPreDiv>
                      <Card>
                        <SyntaxHighlighter
                          style={syntaxHighlightTheme}
                          language="javascript"
                          lineProps={{
                            style: {
                              whiteSpace: 'pre-wrap',
                            },
                          }}
                          wrapLines={true}
                          {...props}
                        >
                          {String(children)
                            .replace(/\n$/, '')
                            .replace(/,(?=\S)/, ', ')
                            .replace(/\/(?=\S)/g, '/ ')}
                        </SyntaxHighlighter>
                      </Card>
                    </BlogPostPreDiv>
                  ) : (
                    <BlogCode className={className} {...props}>
                      {String(children)
                        .replace(/\n$/, '')
                        .replace(/,(?=\S)/g, ', ')
                        .replace(/\/(?=\S)/g, '/ ')}
                    </BlogCode>
                  );
                },
                p: ({ node, children, ...props }) => (
                  <BlogPostP>{children}</BlogPostP>
                ),
                ul: ({ node, children, ...props }) => (
                  <BlogPostUL>{children}</BlogPostUL>
                ),
              }}
            />
          </BlogPostContent>
        </Card>
      </BlogPostInner>
    </BlogPostWrapper>
  );
};

export default BlogPost;

import Card from "../Card";
import {
  AboutMeBeforeHeader,
  AboutMeGeneralText,
  AboutMeHeader,
  AboutMeInner,
  AboutMeWrapper,
} from "./styled";

const AboutMe = () => {
  return (
    <AboutMeWrapper>
      <AboutMeInner>
        <AboutMeHeader
          gridC="6 / span 7"
          gridR="1 / span 1"
          tabletGridC="7 / span 6"
          tabletGridR="1 / span 1"
        >
          About Me
        </AboutMeHeader>
        <AboutMeBeforeHeader
          gridC="1 / span 5"
          gridR="1 / span 1 "
          tabletGridC="2 / span 5"
          tabletGridR="1 / span 1"
        />
        <Card
          title="Hello"
          gridC="1 / span 4"
          gridR="2 / span 2"
          tabletGridC="2 / span 4"
          tabletGridR="2 / span 2"
          background="/me.png"
        ></Card>
        <Card
          title="A bit about me"
          gridC="6 / span 7"
          gridR="2 / span 1"
          tabletGridC="7 / span 5"
          tabletGridR="2 / span 2"
        >
          <AboutMeGeneralText>
            Hey! I'm Stefan, Just a 30-something from a small town on the east
            coast of the UK. I'm a dad to 2 beautiful daughters, and I have
            another on the way in July of 2022!
          </AboutMeGeneralText>
        </Card>
        <Card
          title="Where it began"
          gridC="1 / span 5"
          gridR="4 / span 1"
          tabletGridC="2 / span 5"
          tabletGridR="4 / span 1"
        >
          <AboutMeGeneralText>
            My passion for coding started in the days of Geocities sites,
            MySpace customisations with glittery backgrounds, and Nokia 3210's,
            now come on who else remembers those days..
          </AboutMeGeneralText>
        </Card>
        <Card
          title="What I do now"
          gridC="7 / span 6"
          gridR="3 / span 2"
          tabletGridC="8 / span 4"
          tabletGridR="4 / span 1"
        >
          <AboutMeGeneralText>
            These days, life is a bit more serious and I actually get paid to do
            what I love! I work as a JavaScript developer, I've been in my
            current role since November 2016, but overall I have been with my
            current employer since November 2012.
          </AboutMeGeneralText>
        </Card>
        <Card
          title="A bit about me"
          gridC="1 / span 12"
          gridR="6 / span 1"
          tabletGridC="2 / span 10"
          tabletGridR="6 / span 1"
        >
          <AboutMeGeneralText>
            I love to learn, however it's hard to find time at times whilst
            working a full time job, and being a dad. I am always looking for
            idea to help with that, if you know any then please do reach out.
          </AboutMeGeneralText>
        </Card>
      </AboutMeInner>
    </AboutMeWrapper>
  );
};

export default AboutMe;

import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { desktopMin } from '../constants/constants';

interface IAppContext {
  isAppLoading: boolean;
  setIsAppLoading: Dispatch<SetStateAction<boolean>>;
  isAuthStatusLoading: boolean;
  setIsAuthStatusLoading: Dispatch<SetStateAction<boolean>>;
  isBlogLoading: boolean;
  setIsBlogLoading: Dispatch<SetStateAction<boolean>>;
  isWeatherLoading: boolean;
  setIsWeatherLoading: Dispatch<SetStateAction<boolean>>;
  isDesktop: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export const AppContext = React.createContext<IAppContext>({
  isAppLoading: false,
  setIsAppLoading: () => null,
  isAuthStatusLoading: false,
  setIsAuthStatusLoading: () => null,
  isBlogLoading: false,
  setIsBlogLoading: () => null,
  isWeatherLoading: false,
  setIsWeatherLoading: () => null,
  isDesktop: true,
  isMenuOpen: false,
  setIsMenuOpen: () => null,
});

export const AppProvider: React.FC = ({ children }) => {
  const [appLoadingTime] = useState(new Date().getTime());
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [isBlogLoading, setIsBlogLoading] = useState(true);
  const [isWeatherLoading, setIsWeatherLoading] = useState(false); //TODO: These need to default to true, they need theior setter functions calling
  const [isAuthStatusLoading, setIsAuthStatusLoading] = useState(true); //TODO: These need to default to true, they need theior setter functions calling
  const [isDesktop, setIsDesktop] = useState(matchMedia(desktopMin).matches);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleResize = () => {
    setIsDesktop(matchMedia(desktopMin).matches);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isBlogLoading && !isWeatherLoading && !isAuthStatusLoading) {
      const timeToLoadBlog = new Date().getTime() - appLoadingTime;
      let timeoutPeriod = 0;
      console.log(`TimeToLoadBlog: ${timeToLoadBlog}ms`);
      if (timeToLoadBlog < 6000) timeoutPeriod = 6000 - timeToLoadBlog;
      console.log(`TimeoutPeriod: ${timeoutPeriod}ms`);
      setTimeout(() => setIsAppLoading(false), timeoutPeriod);
    }
  }, [isBlogLoading, isWeatherLoading, isAuthStatusLoading]);

  return (
    <AppContext.Provider
      value={{
        isAppLoading,
        setIsAppLoading,
        isBlogLoading,
        setIsBlogLoading,
        isWeatherLoading,
        setIsWeatherLoading,
        isAuthStatusLoading,
        setIsAuthStatusLoading,
        isDesktop,
        isMenuOpen,
        setIsMenuOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { desktopMin, tabletMin } from '../../constants/constants';

interface NavProps {
  isMainNavToggler?: boolean;
  isMainNavToggled?: boolean;
  isSubNavVisible?: boolean;
}

const HeaderNav = styled.nav<NavProps>`
  width: 100%;
  height: 50px;
  min-height: ${({ isMainNavToggled }) =>
    isMainNavToggled ? '100vh' : '50px'};
  position: sticky;
  top: 0;
  background-color: var(--background);
  z-index: 10;
  ${({ isMainNavToggled }) =>
    isMainNavToggled
      ? `position: fixed;
          top: 0;
          left: 0;
          z-index: 10;
          background-color: var(--background);
        `
      : ``}
  @media screen and ${desktopMin} {
    height: 100px;
  }
`;

const HeaderNavMenu = styled.ul<NavProps>`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  height: 100%;
  @media screen and ${tabletMin} {
    justify-content: center;
  }
  @media screen and ${desktopMin} {
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    background: none;
  }
`;

const HeaderNavMenuLogo = styled.li<NavProps>`
  font-size: 20px;
  padding: 7.5px 10px 0 50px;
  height: 100%;
  display: none;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  @media screen and ${desktopMin} {
    flex: 1;
    order: 0;
    display: block;
  }
`;

const HeaderNavMenuItem = styled.li<NavProps>`
  padding: 0 10px;
  order: 2;
  width: 100%;
  text-align: center;
  height: 50px;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: ${({ isMainNavToggled, isMainNavToggler }) =>
    isMainNavToggled || isMainNavToggler ? `flex` : `none`};
  @media screen and ${desktopMin} {
    order: 1;
    position: relative;
    display: flex;
    width: auto;
    height: 100px;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
`;

const HeaderNavMenuItemLink = styled(Link)`
  display: flex;
  padding: 10px 5px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  color: var(--mainText);
  @media screen and ${desktopMin} {
    justify-content: left;
  }
`;

const HeaderNavSubmenu = styled.ul<NavProps>`
  list-style-type: none;
  display: ${(props) => (props.isSubNavVisible ? 'flex' : 'none')};
  background-color: var(--navBackground);
  border-radius: 3px;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  @media screen and ${desktopMin} {
    ${(props) =>
      props.isSubNavVisible
        ? css`
            border-radius: 0px 0px 6px 6px;
            position: absolute;
            background-color: var(--navBackground);
            left: 0;
            top: 100px;
            z-index: 2;
            border-bottom: var(--navBorder);
            border-left: var(--navBorder);
            border-right: var(--navBorder);
            border-top: 1px solid var(--navBackground);
          `
        : ''}
  }
`;

const HeaderNavSubmenuItem = styled.li<NavProps>`
  padding: 10px;
`;

const HeaderNavSubmenuItemLink = styled(Link)`
  display: flex;
  padding: 10px 5px;
  color: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
`;

const HeaderNavPrimaryButton = styled.li<NavProps>`
  padding: 5px 5px;
  order: 3;
  width: 100%;
  text-align: center;
  display: ${({ isMainNavToggled }) => (isMainNavToggled ? `flex` : `none`)};
  align-items: center;
  justify-content: center;
  align-content: center;
  @media screen and ${tabletMin} {
    border: 0;
    width: auto;
    order: 1;
    display: flex;
    height: 100px;
    padding: 0 5px;
  }
  @media screen and ${desktopMin} {
    order: 1;
    position: relative;
    display: block;
    width: auto;
    padding: 0 5px;
  }
`;

const HeaderNavPrimaryButtonLink = styled(Link)`
  background: var(--navLinkCTA);
  color: var(--ctaText);
  text-decoration: none;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and ${tabletMin} {
    margin: 0 5px;
    height: 100%;
  }
  @media screen and ${desktopMin} {
    margin: 0 5px;
    height: 100%;
  }
`;

const HeaderNavToggle = styled.div<NavProps>`
display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    border: none;
    cursor: pointer;
    padding: ${({ isMainNavToggled }) =>
      isMainNavToggled ? `0 0 0 3.54px` : 0};
    z-index: 10;
}
  @media screen and ${desktopMin} {
    display: none;
  }
  &:focus {
    outline: none;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: var(--mainText);
    border-radius: 10px;
    transform-origin: 1px;
    &:nth-child(1) {
      transform: ${({ isMainNavToggled }) =>
        isMainNavToggled ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ isMainNavToggled }) =>
        isMainNavToggled ? 'translate(100%)' : 'translate(0)'};
      opacity: ${({ isMainNavToggled }) => (isMainNavToggled ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ isMainNavToggled }) =>
        isMainNavToggled ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

export {
  HeaderNav,
  HeaderNavMenu,
  HeaderNavMenuLogo,
  HeaderNavMenuItem,
  HeaderNavMenuItemLink,
  HeaderNavSubmenu,
  HeaderNavSubmenuItem,
  HeaderNavSubmenuItemLink,
  HeaderNavPrimaryButton,
  HeaderNavPrimaryButtonLink,
  HeaderNavToggle,
};

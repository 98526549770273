import axios from 'axios';
import React, { useEffect } from 'react';
import { useAuthContext } from '../../hooks/contextHooks';

const ProfilePage = () => {
  const { user } = useAuthContext();
  useEffect(() => {
    if (user) {
      axios.get(
        `https://us-central1-personalwebsite-57bb3.cloudfunctions.net/api/users`,
        {
          headers: {
            Authorization: `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjI3ZGRlMTAyMDAyMGI3OGZiODc2ZDdiMjVlZDhmMGE5Y2UwNmRiNGQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcGVyc29uYWx3ZWJzaXRlLTU3YmIzIiwiYXVkIjoicGVyc29uYWx3ZWJzaXRlLTU3YmIzIiwiYXV0aF90aW1lIjoxNjQ1MTkzMTQ0LCJ1c2VyX2lkIjoidlFtVXNiUXgwN1ZZVGRPMkpXdTM0T3Zma0JDMyIsInN1YiI6InZRbVVzYlF4MDdWWVRkTzJKV3UzNE92ZmtCQzMiLCJpYXQiOjE2NDUxOTMxNDQsImV4cCI6MTY0NTE5Njc0NCwiZW1haWwiOiJzdGVmYW53cmlnaHQxOTg4QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJzdGVmYW53cmlnaHQxOTg4QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.iXN024EzP-qiAG_8cRelvo-G-pl0eh79ZdhQqCOVL4W-GN2mhbKMqzJNuC3jwVKBc2O8UG7mkaRneSNqIdrQU9Th-4SrQ1FLVyR0E2Y0S38nAVy_MbT4jZPjS3XSPqbjtvmdmvdVqRD7wHrSld3xjrFY0ILDY4lJW8A1MzoTEmw-TL-G-oFEYvMk85bxwqvd_CFJC2rAQysAQqPdMvX0FTm1YOvx5soFrfdCF5ObuU_wgtSw06Wa0h5ZpekpNHNPgAZm_3WY6vUuGDrDYH2sh-Ox3mOd1FsfJ2Ez4Hs84AJTj5Klh1V9SgHyzSajwzaYXnnjAag-lItpkN-Y_ouDuQ`,
          },
        }
      );
    }
  }, [user]);

  return <div></div>;
};

export default ProfilePage;

import React, { useEffect, useState } from 'react';
import { PaginationContainer } from './styled';
import ReactPaginate from 'react-paginate';
import { IBlogPostData } from '../../../context/BlogContext';
import Card from '../../Card';
import {
  BlogListPostContainer,
  BlogListPostTagsList,
  BlogListPostTagsListItem,
} from '../List/styled';
import { AiFillTags } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';

interface IPaginationProps {
  gridC: string;
  gridR: string;
  tabletGridC: string;
  tabletGridR: string;
}

interface IItemsProps {
  currentItems: IBlogPostData[];
}

interface IPaginatedItemsProps {
  itemsPerPage: number;
  blogList: IBlogPostData[];
  noPosts: boolean;
}

function Items(props: React.PropsWithChildren<IItemsProps>) {
  const location = useLocation();
  return (
    <>
      {props.currentItems &&
        props.currentItems.map((post: any) => (
          <Card
            key={post.id}
            title={post.title}
            links={{
              Right: { name: 'Read More', link: post.slug, type: 'internal' },
              Left: { name: post.source, link: post.url, type: 'external' },
            }}
          >
            {post.description}
            <BlogListPostTagsList>
              {post.tag_list.map((tag: any, index: any) => (
                <BlogListPostTagsListItem key={index}>
                  <AiFillTags />
                  <Link
                    to={`/blog/filtered/${tag}`}
                    state={{ from: location.pathname }}
                  >
                    {tag}
                  </Link>
                </BlogListPostTagsListItem>
              ))}
            </BlogListPostTagsList>
          </Card>
        ))}
    </>
  );
}
function BlogPaginatedItems(
  props: React.PropsWithChildren<IPaginatedItemsProps>
) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState<IBlogPostData[]>([
    {
      id: 0,
      title: '',
      description: '',
      cover_image: '',
      tag_list: [''],
      slug: '',
      url: '',
      comments_count: 0,
      positive_reactions_count: 0,
      public_reactions_count: 0,
      page_views_count: 0,
      published_timestamp: '2021-01-01T00:01:00.000Z',
      body_markdown: '',
      reading_time_minutes: 0,
      source: '',
    },
  ]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(props.blogList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(props.blogList.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage, props.blogList]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset =
      (event.selected * props.itemsPerPage) % props.blogList.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <BlogListPostContainer
        gridC="1 / span 12"
        gridR={props.noPosts ? '4 / span 1' : '3 / span 1'}
        tabletGridC="1 / span 12"
        tabletGridR={props.noPosts ? '3 / span 1' : '2 / span 1'}
      >
        <Items currentItems={currentItems} />
      </BlogListPostContainer>
      <PaginationContainer
        gridC="1 / span 12"
        gridR={props.noPosts ? '5 / span 1' : '4 / span 1'}
        tabletGridC="1 / span 12"
        tabletGridR={props.noPosts ? '4 / span 1' : '3 / span 1'}
      >
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
        />
      </PaginationContainer>
    </>
  );
}

export { BlogPaginatedItems };

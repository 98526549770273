import MyLogo from "../Logo";
import { FullScreenLoaderContainer, FullScreenLoaderLogo } from "./styled";

const FullScreenLoader = () => {
  return (
    <FullScreenLoaderContainer>
      <FullScreenLoaderLogo>
        <MyLogo />
      </FullScreenLoaderLogo>
    </FullScreenLoaderContainer>
  );
};

export { FullScreenLoader };

import React from 'react';
import { AppContext } from '../context/AppContext';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import { BlogContext } from '../context/BlogContext';

export const useAppContext = () => React.useContext(AppContext);
export const useThemeContext = () => React.useContext(ThemeContext);
export const useAuthContext = () => React.useContext(AuthContext);
export const useBlogContext = () => React.useContext(BlogContext);

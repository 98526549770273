import { Link } from "react-router-dom";
import styled from "styled-components";
import { tabletMin, desktopMin } from "../../constants/constants";
import { ICardProps } from "./index";

const CardWrapper = styled.article<ICardProps>`
  color: var(--cardMainText);
  position: relative;
  background-color: transparent;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  box-shadow: ${(props) =>
    props.themeType === "light"
      ? "var(--cardMainBackground) 4px 4px 4px 1px"
      : ""};
  border-radius: 20px;
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
`;

const CardList = styled.ul`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 0px 0 15px 0;
  list-style: none;
`;

const CardHeader = styled.header`
  height: auto;
  min-height: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  border: 2px solid var(--cardBorder);
  padding: 0px 5px;
  background: var(--cardHeaderBackground);
  color: var(--cardHeaderText);
  border-radius: 20px 20px 0 0;
`;

const CardDots = styled.div`
  display: flex;
`;
const CardDot = styled.div`
  background-color: var(--cardHeaderDots);
  width: 1rem;
  border-radius: 50%;
  height: 1rem;
  margin-right: 0.25rem;
`;

const CardContent = styled.div<ICardProps>`
  padding: 1rem;
  background-color: var(--cardMainBackground);
  border-right: 2px solid var(--cardBorder);
  border-left: 2px solid var(--cardBorder);
  ${(props) =>
    props.background
      ? `
      min-height: 150px;
      max-width: 75vw;
      aspect-ratio: 1;
      background-image: url(${props.background});
      background-size: cover`
      : ""};
`;

const CardFooter = styled.footer`
  height: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  border: 2px solid var(--cardBorder);
  background-color: var(--cardMainBackground);
  border-radius: 0 0 20px 20px;
  padding: 0 25px;
`;

const CardFooterInternalLink = styled(Link)`
  display: flex;
  align-items: center;
`;
const CardFooterExternalLink = styled.a`
  display: flex;
  align-items: center;
`;
export {
  CardWrapper,
  CardList,
  CardFooter,
  CardFooterInternalLink,
  CardFooterExternalLink,
  CardHeader,
  CardDots,
  CardDot,
  CardContent,
};

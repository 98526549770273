import React, { useEffect, useState } from "react";

type TTheme = "dark" | "light";

const preferredColorScheme = window.matchMedia("(prefers-color-scheme: dark)")
  ? "dark"
  : "light";

interface ITheme {
  background: string;
  secondaryBackground: string;
  mainText: string;
  subHeadingText: string;
  navCTA: string;
  navCTAText: string;
  CTABackground: string;
  CTAText: string;
  cardMainBackground: string;
  cardMainText: string;
  cardHeaderBackground: string;
  cardHeaderText: string;
  cardBorder: string;
  paginationBorder: string;
  paginationButtonBackground: string;
  paginationButtonText: string;
  paginationPageInactiveBackground: string;
  paginationPageInactiveText: string;
  paginationPageActiveBackground: string;
  paginationPageActiveText: string;
}

const themes = {
  light: {
    background: "#F7F8F9",
    secondaryBackground: "#1A1A37",
    mainText: "#1A1A37",
    subHeadingText: "#5785d5",
    navCTA: "#5785d5",
    navCTAText: "#1A1A37",
    CTABackground: "#1A1A37",
    CTAText: "#F7F8F9",
    cardMainBackground: "#1A1A37",
    cardMainText: "#F7F8F9",
    cardHeaderBackground: "#5785d5",
    cardHeaderText: "#1A1A37",
    cardHeaderDots: "#1A1A37",
    cardBorder: "#F7F8F9",
    paginationBorder: "#1A1A37",
    paginationButtonBackground: "#1A1A37",
    paginationButtonText: "#F7F8F9",
    paginationPageInactiveBackground: "#F7F8F9",
    paginationPageInactiveText: "#1A1A37",
    paginationPageActiveBackground: "#5785d5",
    paginationPageActiveText: "#1A1A37",
  },
  dark: {
    background: "#1A1A37",
    secondaryBackground: "#F7F8F9",
    mainText: "#F7F8F9",
    subHeadingText: "#5785d5",
    navCTA: "#5785d5",
    navCTAText: "#1A1A37",
    CTABackground: "#F7F8F9",
    CTAText: "#1A1A37",
    cardMainBackground: "#F7F8F9",
    cardMainText: "#1A1A37",
    cardHeaderBackground: "#5785d5",
    cardHeaderText: "#1A1A37",
    cardHeaderDots: "#1A1A37",
    cardBorder: "#1A1A37",
    paginationBorder: "#5785d5",
    paginationButtonBackground: "#F7F8F9",
    paginationButtonText: "#1A1A37",
    paginationPageInactiveBackground: "#1A1A37",
    paginationPageInactiveText: "#F7F8F9",
    paginationPageActiveBackground: "#5785d5",
    paginationPageActiveText: "#1A1A37",
  },
};

interface IThemeContext {
  themeType: TTheme;
  theme: ITheme;
  setCurrentTheme: () => void;
}

export const ThemeContext = React.createContext<IThemeContext>({
  themeType: preferredColorScheme,
  theme: themes[preferredColorScheme],
  setCurrentTheme: () => null,
});

export const ThemeProvider: React.FC = ({ children }) => {
  const [currentThemeType, setCurrentThemeType] =
    useState<TTheme>(preferredColorScheme);
  const [currentTheme, setCurrentTheme] = useState<ITheme>(
    themes[preferredColorScheme]
  );

  const changeTheme = () => {
    setCurrentThemeType(currentThemeType === "light" ? "dark" : "light");
    setCurrentTheme(
      currentThemeType === "light" ? themes["dark"] : themes["light"]
    );
  };

  const getThemeValue = (key: keyof ITheme) => {
    return currentTheme[key];
  };

  const setCSSVariables = (theme: ITheme) => {
    for (const value in theme) {
      document.documentElement.style.setProperty(
        `--${value}`,
        getThemeValue(value as keyof ITheme)
      );
    }
  };

  useEffect(() => {
    setCSSVariables(themes[currentThemeType]);
  });

  return (
    <ThemeContext.Provider
      value={{
        themeType: currentThemeType,
        theme: themes[currentThemeType],
        setCurrentTheme: changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

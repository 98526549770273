import styled from 'styled-components';
import { desktopMin } from '../../constants/constants';

interface ToggleProps {
  isDark?: boolean;
}
const ToggleContainer = styled.div<ToggleProps>`
  padding: 4px;
  border-radius: 40px;
  box-sizing: content-box;
  width: 90px;
  border: 4px solid var(--secondaryBackground);
  transition: all 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-sizing: content-box;
  background-color: ${({ isDark }) => (isDark ? 'black' : 'skyblue')};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

const ToggleSwitchFigure = styled.div`
  position: absolute;
  left: 20px;
  display: block;
  width: 80px;
  height: 30px;
  border: 8px solid var(--background);
  border-radius: 20px;
  background-color: #fff;
  transform: scale(0.4);
  transition: all 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-sizing: content-box;
  &:before {
    content: '';
    display: block;
    position: relative;
    top: -25px;
    right: -10px;
    width: 30px;
    height: 30px;
    border: 8px solid #d4d4d2;
    border-radius: 100%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: rotateZ(30deg);
    background-color: #fff;
  }
  &:after {
    content: '';
    display: block;
    position: relative;
    top: -65px;
    right: -42px;
    width: 15px;
    height: 15px;
    border: 8px solid #d4d4d2;
    border-radius: 100%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: rotateZ(70deg);
    background-color: #fff;
  }
`;

const ToggleSwitchFigureAlt = styled.div`
  content: '';
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #efeeda;
  border-radius: 100%;
  border: 4px solid #dee1c5;
  box-shadow: 42px -5px 0 -3px #8892af, 68px -4px 0 -3px #8892af,
    54px 4px 0 -4px #8892af, 77px 7px 0 -2px #8892af, 63px 18px 0 -4px #8892af,
    44px 26px 0 -2px #8892af, 72px 23px 0 -3px #8892af;
  transition: all 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(0);
  box-sizing: content-box;
  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 11px;
    width: 7px;
    height: 7px;
    background-color: #efeeda;
    border-radius: 100%;
    border: 4px solid #dee1c5;
  }
  &:after {
    content: '';
    position: absolute;
    top: 16px;
    left: 4px;
    width: 2px;
    height: 2px;
    background-color: #efeeda;
    border-radius: 100%;
    border: 4px solid #dee1c5;
  }
`;

const ToggleSwitch = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 50px;
  background-color: #f5eb42;
  border: 4px solid #e4c74d;
  border-radius: 50%;
  transition: all 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-sizing: content-box;
`;
const ToggleCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  z-index: 2;
  opacity: 0;
  &:checked ~ ${ToggleSwitch} {
    margin-left: 0;
    border-color: #dee1c5;
    background-color: #fffdf2;
  }
  &:checked ${ToggleContainer} {
    background-color: #0a192f;
    border-color: #8892af;
  }
  &:checked ~ ${ToggleSwitch} ${ToggleSwitchFigure} {
    margin-left: 40px;
    opacity: 0;
    transform: scale(0.1);
  }
  &:checked ~ ${ToggleSwitch} ${ToggleSwitchFigureAlt} {
    transform: scale(1);
  }
`;

export {
  ToggleContainer,
  ToggleCheckbox,
  ToggleSwitch,
  ToggleSwitchFigure,
  ToggleSwitchFigureAlt,
};

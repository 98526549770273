import {
  GithubAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router";
import { auth, registerUsingEmailAndPassword, signInUsingEmailAndPassword, signInUsingGoogle } from "../../firebase/firebase";
import { useAuthContext } from "../../hooks/contextHooks";
import {
  LoginFormContainer,
  LoginFormForm,
  LoginFormFormGroup,
  LoginFormFormGroupWrapperHalf,
  LoginFormFormGroupWrapperFull,
  LoginFormFormGroupWrapperLabel,
  LoginFormFormGroupWrapperTextInput,
  LoginFormLeft,
  LoginFormRight,
  LoginFormLeftHeader,
  LoginFormRightHeader,
  LoginFormSocialsHeader,
  LoginFormButton,
  LoginFormButtonText,
  LoginFormLeftContent,
  LoginFormLeftContentList,
  LoginFormLeftContentListItem,
} from "./styled";

const AuthGate = () => {
  const navigate = useNavigate();
  const { setUser } = useAuthContext();

  const [isLogin, setIsLogin] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formHasError, setFormHasError] = useState(false);

  const handleFirstNameChange = (e: any) => {
    setFirstName(e.target.value);
      setFormSubmitted(false);
  };
  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value);
      setFormSubmitted(false);

  };
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
      setFormSubmitted(false);

  };
  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
      setFormSubmitted(false);

  };

  const verifyForm = () => {
    return firstName !== "" && lastName !== "" && email !== "" && password !== "";
  }

  const handleLoginSignup = (isLogin: boolean, provider: string) => {
    if (isLogin) {
      switch (provider) {
        case "google":
          signInUsingGoogle()
          break;
        case "twitter":
          signInWithPopup(auth, new TwitterAuthProvider())
            .then((res) => {
              const cred = TwitterAuthProvider.credentialFromResult(res);
              const token = cred?.accessToken;
              setUser(res.user);
              navigate("/");
            })
            .catch((error) => {
              console.log(error.message);
            });
          break;
        case "github":
          signInWithPopup(auth, new GithubAuthProvider())
            .then((res) => {
              const cred = GithubAuthProvider.credentialFromResult(res);
              const token = cred?.accessToken;
              setUser(res.user);
              navigate("/");
            })
            .catch((error) => {
              console.log(error.message);
            });
          break;
        case "email":
              signInUsingEmailAndPassword(email, password)
          break;
        default:
          alert("Method not implemented");
          break;
      }
    } else {
      registerUsingEmailAndPassword(`${firstName} ${lastName}`, email, password);
    }
    console.log(`IsLogin? ${isLogin}`);
    return true;
  };
  return (
    <LoginFormContainer>
      <LoginFormLeft>
        <LoginFormLeftHeader>Why Register</LoginFormLeftHeader>
        <LoginFormLeftContent>If you choose to register you can have access to the following:</LoginFormLeftContent>
        <LoginFormLeftContentList>
          <LoginFormLeftContentListItem>Personal blog posts - Not just the techy stuff!</LoginFormLeftContentListItem>
          <LoginFormLeftContentListItem>Store your game history</LoginFormLeftContentListItem>
          <LoginFormLeftContentListItem>... and maybe more in the future</LoginFormLeftContentListItem>
        </LoginFormLeftContentList>
      </LoginFormLeft>
      <LoginFormRight>
        <LoginFormRightHeader>
          {isLogin ? "Login" : "Signup"}
        </LoginFormRightHeader>
        <LoginFormForm>
          {isLogin ? (
            ""
          ) : (
            <LoginFormFormGroup>
              <LoginFormFormGroupWrapperHalf>
                <LoginFormFormGroupWrapperLabel>
                  First Name
                </LoginFormFormGroupWrapperLabel>
                <LoginFormFormGroupWrapperTextInput onChange={handleFirstNameChange} value={firstName} type="text"></LoginFormFormGroupWrapperTextInput>
              </LoginFormFormGroupWrapperHalf>
              <LoginFormFormGroupWrapperHalf>
                <LoginFormFormGroupWrapperLabel>
                  Last Name
                </LoginFormFormGroupWrapperLabel>
                <LoginFormFormGroupWrapperTextInput onChange={handleLastNameChange} value={lastName} type="text"></LoginFormFormGroupWrapperTextInput>
              </LoginFormFormGroupWrapperHalf>
            </LoginFormFormGroup>
          )}
          <LoginFormFormGroup>
            <LoginFormFormGroupWrapperFull>
              <LoginFormFormGroupWrapperLabel>
                E-mail
              </LoginFormFormGroupWrapperLabel>
              <LoginFormFormGroupWrapperTextInput onChange={handleEmailChange} value={email} type="email"></LoginFormFormGroupWrapperTextInput>
            </LoginFormFormGroupWrapperFull>
          </LoginFormFormGroup>
          <LoginFormFormGroup>
            <LoginFormFormGroupWrapperFull>
              <LoginFormFormGroupWrapperLabel>
                Password
              </LoginFormFormGroupWrapperLabel>
              <LoginFormFormGroupWrapperTextInput onChange={handlePasswordChange} value={password} type="password"></LoginFormFormGroupWrapperTextInput>
            </LoginFormFormGroupWrapperFull>
          </LoginFormFormGroup>
        </LoginFormForm>
        <LoginFormButton onClick={() => handleLoginSignup(isLogin, "email")}>
          <LoginFormButtonText>
            {isLogin ? "Login" : "Signup"}
          </LoginFormButtonText>
        </LoginFormButton>
        <LoginFormSocialsHeader>or</LoginFormSocialsHeader>
        <LoginFormButton onClick={() => setIsLogin((prev) => !prev)}>
          <LoginFormButtonText>
            {isLogin ? "Signup" : "Login"}
          </LoginFormButtonText>
        </LoginFormButton>
        {/* <LoginFormDivider />
        <LoginFormButton onClick={() => handleLoginSignup(isLogin, "google")}>
          <Google2 size="25" />
          <LoginFormButtonText>
            {isLogin ? "Login" : "Signup"} with Google
          </LoginFormButtonText>
        </LoginFormButton>
        <LoginFormButton onClick={() => handleLoginSignup(isLogin, "twitter")}>
          <Twitter size="25" />
          <LoginFormButtonText>
            {isLogin ? "Login" : "Signup"} with Twitter
          </LoginFormButtonText>
        </LoginFormButton>
        <LoginFormButton onClick={() => handleLoginSignup(isLogin, "github")}>
          <Github size="25" />
          <LoginFormButtonText>
            {isLogin ? "Login" : "Signup"} with Github
          </LoginFormButtonText>
        </LoginFormButton> */}
      </LoginFormRight>
    </LoginFormContainer>
  );
};

export default AuthGate;

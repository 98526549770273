import styled from 'styled-components';
import { desktopMin } from '../../constants/constants';

const MainWrapper = styled.main`
  margin: 0px auto;
  width: 100%;
  max-width: 1400px;
  min-height: calc(100vh - 120px);
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  @media screen and ${desktopMin} {
    justify-content: center;
  }
`;

export { MainWrapper };

import styled from 'styled-components';
import { desktopMin, tabletMin } from '../../constants/constants';
import { H1, H2 } from '../../style/styled';
import { ICardProps } from '../Card';

const HomeWrapper = styled.section`
  padding-top: 10px;
  @media screen and ${desktopMin} {
    padding-top: 2rem;
    min-height: calc(100vh - 100px);
  }
`;

const HomeInner = styled.div`
  display: grid;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  @media screen and ${tabletMin} {
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-auto-rows: fit-content(5rem);
  }
  @media screen and ${desktopMin} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const HomeHeader = styled(H1)<ICardProps>`
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
`;

const HomeSubHeader = styled(H2)<ICardProps>`
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
`;

export { HomeWrapper, HomeInner, HomeHeader, HomeSubHeader };

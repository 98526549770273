import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './context/ThemeContext';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './context/AuthContext';
import { BlogProvider } from './context/BlogContext';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <AuthProvider>
        <ThemeProvider>
          <BlogProvider>
            <App />
          </BlogProvider>
        </ThemeProvider>
      </AuthProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

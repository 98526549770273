import styled from "styled-components";
import { desktopMin, tabletMin } from "../../constants/constants";
import { GeneralText, H1 } from "../../style/styled";

interface AboutMeProps {
  gridC: string;
  gridR: string;
  tabletGridC: string;
  tabletGridR: string;
}

const AboutMeWrapper = styled.section`
  padding-top: 10px;
  @media screen and ${desktopMin} {
    padding-top: 2rem;
    min-height: calc(100vh - 100px);
  }
`;

const AboutMeInner = styled.div`
  display: grid;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  @media screen and ${tabletMin} {
    grid-template-rows: minmax(8rem, auto) 1fr;
    grid-template-columns: repeat(12, [col-start] 1fr);
    padding-left: 0;
    padding-right: 0;
  }
`;

const AboutMeBeforeHeader = styled.div<AboutMeProps>`
  border: 1px solid var(--mainText);
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
`;

const AboutMeHeader = styled(H1)<AboutMeProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px 0px;
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
`;

const AboutMeTextContainer = styled.div`
  grid-column-start: 5;
  grid-column-end: 12;
`;

const AboutMeGeneralText = styled(GeneralText)`
  grid-column: 5 / span 8;
  grid-row: span 1;
  max-width: unset;
  margin: 0;
  color: var(--secondaryText);
`;

export {
  AboutMeWrapper,
  AboutMeInner,
  AboutMeBeforeHeader,
  AboutMeHeader,
  AboutMeGeneralText,
  AboutMeTextContainer,
};

import React from "react";
import { useThemeContext } from "../../hooks/contextHooks";
import Card from "../Card";
import {
  WorkCardImage,
  WorkCardInner,
  WorkCardDetails,
  WorkInner,
  WorkWrapper,
  WorkCardStarted,
  WorkCardJobTitle,
  WorkCardLeft,
  WorkCardCompany,
  WorkCardP,
} from "./styled";

const Work = () => {
  const { themeType } = useThemeContext();
  return (
    <WorkWrapper>
      <WorkInner>
        <Card
          title="Glassbox"
          gridC="1 / span 12"
          gridR="1 / span 3"
          tabletGridC="2 / span 10"
          tabletGridR="2 / span 1"
        >
          <WorkCardInner>
            <WorkCardImage src="/glassbox.webp" themeType={themeType} />
            <WorkCardJobTitle>
              <p>
                Job title: <span>Javascript Developer</span>
              </p>
            </WorkCardJobTitle>
            <WorkCardCompany>
              <p>Employed by: GlassBox (via acquisition of SessionCam)</p>
            </WorkCardCompany>
            <WorkCardStarted>
              <p>
                Started: <span>October 2020</span>
              </p>
            </WorkCardStarted>
            <WorkCardLeft>
              <p>
                <span>Current role</span>
              </p>
            </WorkCardLeft>
            <WorkCardDetails>
              <WorkCardP>Job description:</WorkCardP>
              <WorkCardP>
                My role at Glassbox is within the Detector team, this team's
                main purpose is to build upon and maintain the "Detector
                JavaScript", this script is the main element to recording client
                websites. I officially started working on the Glassbox Detector
                code in March 2022 where I was tasked with bringing code up to
                date with changed from another code branch, ensuring conversions
                to ECMAScript6 were completed. As time moves on I will be
                working on bug identification, fixing, and new feature
                implementation.
              </WorkCardP>
              <WorkCardP>
                Between October 2020 and March 2022, I remained responsible for
                ensuring the SessionCam platform remained online and urgent bug
                fixes were undertaken as part of the "keep the lights on" team.
                I was responsible for ensuring the JIRA ticket backlog was
                maintained and was able to keep the ticket backlog at zero for
                the majority of the time for anything related to the recording
                Javascript and Console functionality.
              </WorkCardP>
            </WorkCardDetails>
          </WorkCardInner>
        </Card>
        <Card
          title="SessionCam"
          gridC="1 / span 6"
          gridR="4 / span 3"
          tabletGridC="2 / span 10"
          tabletGridR="2 / span 1"
        >
          <WorkCardInner>
            <WorkCardImage
              src="/SessionGB_logo-grey.png"
              themeType={themeType}
            />
            <WorkCardJobTitle>
              <p>
                Job title: <span>Javascript Developer</span>
              </p>
            </WorkCardJobTitle>
            <WorkCardCompany>
              <p>Employed by: SessionCam</p>
            </WorkCardCompany>
            <WorkCardStarted>
              <p>
                Started: <span>November 2012</span>
              </p>
            </WorkCardStarted>
            <WorkCardLeft>
              <p>
                Left: <span>October 2020 (acquired by Glassbox)</span>
              </p>
            </WorkCardLeft>
            <WorkCardDetails>
              <WorkCardP>Job description:</WorkCardP>
              <WorkCardP>
                My role from November 2012 - March 2016 was as a Customer
                Success Manager, the role primarily involved providing support
                to new and existing customers on a daily basis through our
                support system. The role also involved the process of seeing a
                new customer through the setup of SessionCam, from getting our
                Javascript tag added to their site, testing the implementation
                to ensure the replays were accurate, and also training the
                customer in the use of our console. Secondary to the main roles,
                I also assisted in the further debugging of any issues
                identified before they were sent to our development team, and
                also the writing of customised Javascript for customers to add
                for further customisation of their SessionCam implementation.
              </WorkCardP>
              <WorkCardP>
                My role from March 2016 was as a Senior Customer Success
                Manager, the role primarily involves managing a Technical
                Support sub-team within the Customer Success team. The team (and
                my role) are responsible for further analysing issues that have
                been raised by customers to the Customer Services sub-team and
                passed to the Technical Support sub-team before they are passed
                to the Development Team. This process ensures that the
                developers have as much information as possible about the issue
                including any replication steps and investigation already
                carried out, this then aids in rectifying an issue faster. The
                role also involves helping with custom code requests that
                clients may need assistance with, these include sending Custom
                Variables, Custom Page Names, Custom Field Names, or Virtual
                Page Loads into the SessionCam system to improve the usability
                of the system to meet the customers requirements.
              </WorkCardP>
              <WorkCardP>
                My role from November 2017 is on the Engineering Team within
                SessionCam, primarily working on our Record and Replay aspects
                of the product. The role involves developing and maintaing the
                JavaScript and C# for both recording and playback elements.
                Primarily my role is fixing bugs raised from the Customer
                Success team or internally in the Engineering team, with the
                addition of occasionally implementing new functionality
              </WorkCardP>
            </WorkCardDetails>
          </WorkCardInner>
        </Card>
      </WorkInner>
    </WorkWrapper>
  );
};

export default Work;

import axios from 'axios';
import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useAppContext } from '../hooks/contextHooks';
const _ = require('lodash');

export interface IBlogPostData {
  id: number;
  title: string;
  description: string;
  cover_image: string;
  tag_list: [string];
  slug: string;
  url: string;
  comments_count: number;
  positive_reactions_count: number;
  public_reactions_count: number;
  page_views_count: number;
  published_timestamp: string;
  body_markdown: string;
  reading_time_minutes: number;
  source: string;
}

interface IBlogContext {
  blogList: IBlogPostData[];
  isLoading: boolean;
  fetchBlogList: Dispatch<SetStateAction<number>>;
  blogTags: string[];
  tagFilterVisibility: boolean;
  setTagFilterVisibility: Dispatch<SetStateAction<boolean>>;
  filteredBlogListLength: number;
  setFilteredBlogListLength: Dispatch<SetStateAction<number>>;
}

export const BlogContext = React.createContext<IBlogContext>({
  blogList: [
    {
      id: 0,
      title: '',
      description: '',
      cover_image: '',
      tag_list: [''],
      slug: '',
      url: '',
      comments_count: 0,
      positive_reactions_count: 0,
      public_reactions_count: 0,
      page_views_count: 0,
      published_timestamp: '2021-01-01T00:01:00.000Z',
      body_markdown: '',
      reading_time_minutes: 0,
      source: '',
    },
  ],
  isLoading: false,
  fetchBlogList: () => null,
  blogTags: [],
  tagFilterVisibility: false,
  setTagFilterVisibility: () => null,
  filteredBlogListLength: 0,
  setFilteredBlogListLength: () => null,
});

export const BlogProvider: React.FC = ({ children }) => {
  const [blogList, setBlogList] = useState<IBlogPostData[]>([
    {
      id: 0,
      title: '',
      description: '',
      cover_image: '',
      tag_list: [''],
      slug: '',
      url: '',
      comments_count: 0,
      positive_reactions_count: 0,
      public_reactions_count: 0,
      page_views_count: 0,
      published_timestamp: '2021-01-01T00:01:00.000Z',
      body_markdown: '',
      reading_time_minutes: 0,
      source: '',
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [blogTags, setBlogTags] = useState<string[]>([]);
  const [tagFilterVisibility, setTagFilterVisibility] = useState(false);
  const [filteredBlogListLength, setFilteredBlogListLength] = useState(0);

  const { setIsBlogLoading } = useAppContext();

  const fetchBlogList = useCallback(() => {
    setIsLoading(true);
    setIsBlogLoading(true);
    axios
      .get(
        `https://us-central1-react-typescript-blog-site.cloudfunctions.net/blog/getPosts`
      )
      .then((fetchedBlog) => {
        //Get the newest blog posts first
        const retVal = _.orderBy(
          fetchedBlog.data.message,
          ['published_timestamp'],
          ['desc']
        );
        let uniqueObjArray = retVal.map(
          (item: { [x: string]: any }) => item['tag_list']
        );
        uniqueObjArray = Array.from(new Set(uniqueObjArray.flat()));
        setBlogList(retVal);
        setBlogTags(uniqueObjArray);
        setFilteredBlogListLength(retVal.length);
      })
      .finally(() => {
        setIsBlogLoading(false);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchBlogList();
  }, [fetchBlogList]);

  return (
    <BlogContext.Provider
      value={{
        blogList,
        isLoading,
        fetchBlogList,
        blogTags,
        tagFilterVisibility,
        setTagFilterVisibility,
        filteredBlogListLength,
        setFilteredBlogListLength,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

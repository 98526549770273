import { useLocation } from "react-router-dom";
import { useAppContext, useAuthContext } from "../../hooks/contextHooks";
import MyLogo from "../Logo";
import ThemeToggle from "../ThemeToggle";
import {
  HeaderNav,
  HeaderNavMenu,
  HeaderNavMenuLogo,
  HeaderNavMenuItem,
  HeaderNavMenuItemLink,
  HeaderNavToggle,
} from "./styled";

const Header = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const { isDesktop, isMenuOpen, setIsMenuOpen } = useAppContext();
  return (
    <HeaderNav isMainNavToggled={isMenuOpen}>
      <HeaderNavMenu>
        <HeaderNavMenuItem
          isMainNavToggler={true}
          isMainNavToggled={isMenuOpen}
        >
          <HeaderNavToggle
            onClick={
              isDesktop
                ? undefined
                : () => {
                    setIsMenuOpen((prevState) => !prevState);
                  }
            }
            isMainNavToggled={isMenuOpen}
          >
            <div></div>
            <div></div>
            <div></div>
          </HeaderNavToggle>
        </HeaderNavMenuItem>
        <HeaderNavMenuLogo>
          <HeaderNavMenuItemLink
            title="Home"
            to={"/"}
            state={{ from: location.pathname }}
            onClick={
              isDesktop
                ? undefined
                : () => {
                    setIsMenuOpen((prevState) => !prevState);
                  }
            }
          >
            <MyLogo />
          </HeaderNavMenuItemLink>
        </HeaderNavMenuLogo>
        <HeaderNavMenuItem isMainNavToggled={isMenuOpen}>
          <HeaderNavMenuItemLink
            title="Home"
            to={"/"}
            state={{ from: location.pathname }}
            onClick={
              isDesktop
                ? undefined
                : () => {
                    setIsMenuOpen((prevState) => !prevState);
                  }
            }
          >
            Home
          </HeaderNavMenuItemLink>
        </HeaderNavMenuItem>
        <HeaderNavMenuItem isMainNavToggled={isMenuOpen}>
          <HeaderNavMenuItemLink
            title="About Me"
            to={"/aboutme"}
            state={{ from: location.pathname }}
            onClick={
              isDesktop
                ? undefined
                : () => {
                    setIsMenuOpen((prevState) => !prevState);
                  }
            }
          >
            About Me
          </HeaderNavMenuItemLink>
        </HeaderNavMenuItem>
        <HeaderNavMenuItem isMainNavToggled={isMenuOpen}>
          <HeaderNavMenuItemLink
            title="Blog"
            to={"/blog"}
            state={{ from: location.pathname }}
            onClick={
              isDesktop
                ? undefined
                : () => {
                    setIsMenuOpen((prevState) => !prevState);
                  }
            }
          >
            Blog
          </HeaderNavMenuItemLink>
        </HeaderNavMenuItem>
        <HeaderNavMenuItem isMainNavToggled={isMenuOpen}>
          <HeaderNavMenuItemLink
            title="Work"
            to={"/work"}
            state={{ from: location.pathname }}
            onClick={
              isDesktop
                ? undefined
                : () => {
                    setIsMenuOpen((prevState) => !prevState);
                  }
            }
          >
            Work
          </HeaderNavMenuItemLink>
        </HeaderNavMenuItem>
        {/*
        <HeaderNavMenuItem isMainNavToggled={isMenuOpen}>
          <HeaderNavMenuItemLink
            title="Games"
            to={"/games"}
            state={{ from: location.pathname }}
            onClick={isDesktop ? undefined : () => {
              setIsMenuOpen((prevState) => !prevState);
            }}
          >
            Games
          </HeaderNavMenuItemLink>
        </HeaderNavMenuItem>
        <HeaderNavPrimaryButton isMainNavToggled={isMenuOpen}>
          {!user ? (
            <HeaderNavPrimaryButtonLink
              to={"/login"}
              state={{ from: location.pathname }}
              onClick={isDesktop ? undefined : () => {
                setIsMenuOpen((prevState) => !prevState);
              }}
            >
              Login
            </HeaderNavPrimaryButtonLink>
          ) : (
            <HeaderNavPrimaryButtonLink
              to={"/"}
              state={{ from: location.pathname }}
              onClick={isDesktop ? undefined : () => {
                logOut();
                setIsMenuOpen((prevState) => !prevState);
              }}
            >
              Logout
            </HeaderNavPrimaryButtonLink>
          )}
        </HeaderNavPrimaryButton> */}
        <HeaderNavMenuItem>
          <ThemeToggle />
        </HeaderNavMenuItem>
      </HeaderNavMenu>
    </HeaderNav>
  );
};

export default Header;

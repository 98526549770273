import styled from 'styled-components';
import { desktopMin, tabletMin } from '../../../constants/constants';

interface IPaginationButtonProps {
  isActive?: boolean;
}

interface IPaginationProps {
  gridC: string;
  gridR: string;
  tabletGridC: string;
  tabletGridR: string;
}

const PaginationContainer = styled.div<IPaginationProps>`
  display: flex;
  @media screen and ${tabletMin} {
    grid-column: ${(props) => props.tabletGridC};
    grid-row: ${(props) => props.tabletGridR};
  }
  @media screen and ${desktopMin} {
    grid-column: ${(props) => props.gridC};
    grid-row: ${(props) => props.gridR};
  }
  & ul {
    display: flex;
    list-style: none;
    height: 3rem;
  }

  & li {
    padding: 0 12px;
    color: var(--paginationPageInactiveText);
    display: flex;
    align-items: center;
    min-width: 32px;
    align-content: center;
    justify-content: center;
    background: var(--paginationPageInactiveBackground);
    border-top: 2px solid var(--paginationBorder);
    border-bottom: 2px solid var(--paginationBorder);
  }

  & li.selected {
    color: var(--paginationPageActiveText);
    background: var(--paginationPageActiveBackground);
  }

  & li.previous {
    padding: 0 12px;
    color: var(--paginationButtonText);
    display: flex;
    align-items: center;
    min-width: 32px;
    border: 2px solid var(--paginationBorder);
    align-content: center;
    justify-content: center;
    background-color: var(--paginationButtonBackground);
    border-radius: 1rem 0 0 1rem;
  }
  & li.next {
    padding: 0 12px;
    color: var(--paginationButtonText);
    display: flex;
    align-items: center;
    min-width: 32px;
    border: 2px solid var(--paginationBorder);
    align-content: center;
    justify-content: center;
    background-color: var(--paginationButtonBackground);
    border-radius: 0 1rem 1rem 0;
  }
`;

export { PaginationContainer };

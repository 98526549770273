// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyC5qjedprPRYIUdtH_nz4iZcUbt-juWFJ0',
  authDomain: 'personalwebsite-57bb3.firebaseapp.com',
  databaseURL: 'https://personalwebsite-57bb3.firebaseio.com',
  projectId: 'personalwebsite-57bb3',
  storageBucket: 'personalwebsite-57bb3.appspot.com',
  messagingSenderId: '770959343268',
  appId: '1:770959343268:web:4e01c894d80134d0a63031',
  measurementId: 'G-6L9WLJMXH8',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics();
const db = getFirestore();
const storage = getStorage(firebaseApp);
const auth = getAuth();

setPersistence(auth, browserSessionPersistence);

const logOut = () => {
  signOut(auth);
};
const googleProvider = new GoogleAuthProvider();
const signInUsingGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      });
    }
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const signInUsingEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const registerUsingEmailAndPassword = async (
  name: any,
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
    });
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

export {
  analytics,
  db,
  storage,
  auth,
  logOut,
  signInUsingGoogle,
  signInUsingEmailAndPassword,
  registerUsingEmailAndPassword,
  sendPasswordReset,
};

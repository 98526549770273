import styled from 'styled-components';

export const H1 = styled.h1`
  margin: 0px;
  font-size: clamp(40px, 8vw, 80px);
  color: var(--mainText);
`;
export const H1LineBefore = styled(H1)`
  &:before {
    content: '';
    display: block;
    position: relative;
    width: 50%;
    max-width: 300px;
    height: 1px;
    margin-right: 20px;
    background-color: var(--mainText);
  }
`;
export const H1LineAfter = styled(H1)`
  &:after {
    content: '';
    display: block;
    position: relative;
    width: 50%;
    max-width: 300px;
    height: 1px;
    margin-left: 20px;
    background-color: var(--mainText);
  }
`;
export const H2 = styled.h2`
  margin: 0px;
  line-height: 0.9;
  font-size: clamp(30px, 6vw, 70px);
  color: var(--subHeadingText);
`;
export const H3 = styled.h3`
  margin: 0px;
  line-height: 0.9;
  font-size: clamp(20px, 3vw, 50px);
  color: var(--subHeadingText);
`;
export const H4 = styled.h4`
  margin: 0px;
  line-height: 0.9;
  font-size: clamp(18px, 2vw, 35px);
  color: var(--subHeadingText);
`;

export const GeneralText = styled.p`
  margin: 20px 0px 0px;
  max-width: 540px;
  color: var(--mainText);
`;

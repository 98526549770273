import styled from 'styled-components';
import { desktopMin } from '../../../constants/constants';
import {
  CardContent,
  CardWrapper,
} from '../../Card/styled';

const BlogPostWrapper = styled.section`
  padding-top: 10px;
  @media screen and ${desktopMin} {
    padding-top: 2rem;
    min-height: calc(100vh - 100px);
  }
  ${CardWrapper} {
    max-width: 90vw;
  }
`;

const BlogPostInner = styled.div`
  display: grid;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
`;

const BlogPostContent = styled.div`
  @media screen and ${desktopMin} {
    padding: 0.5rem 1rem;
  }
`;
const BlogPostP = styled.p`
  @media screen and ${desktopMin} {
    padding: 0.5rem 1rem;
  }
`;

const BlogPostUL = styled.ul`
  padding: 0.5rem 1rem;
  @media screen and ${desktopMin} {
    padding: 0.5rem 3rem;
  }
`;

const BlogPostPreDiv = styled.div`
  ${CardWrapper} {
    padding: 0.5rem 1rem;
  }
  ${CardWrapper} ${CardContent} {
    padding: 0;
  }
`;

const BlogCode = styled.code`
  background-color: #2b2b2b;
  color: #f7f8f9;
  padding: 2px;
  font-size: 16px;
  white-space: pre-wrap;
`;
export {
  BlogPostInner,
  BlogPostWrapper,
  BlogPostContent,
  BlogPostP,
  BlogPostUL,
  BlogPostPreDiv,
  BlogCode,
};

import { useThemeContext } from "../../hooks/contextHooks";
import { ToggleCheckbox, ToggleContainer, ToggleSwitch, ToggleSwitchFigure, ToggleSwitchFigureAlt } from "./styled";

const ThemeToggle = () => {
  const { themeType, setCurrentTheme } = useThemeContext();
  
const isDark = themeType === "dark";
  return (
    <ToggleContainer isDark={isDark}>
      <ToggleCheckbox
        onClick={() => setCurrentTheme()}
        defaultChecked={isDark}
      />
      <ToggleSwitch>
        <ToggleSwitchFigure></ToggleSwitchFigure>
        <ToggleSwitchFigureAlt></ToggleSwitchFigureAlt>
      </ToggleSwitch>
    </ToggleContainer>
  );
};

export default ThemeToggle;

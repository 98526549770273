import React, { useState } from 'react';
import { AiFillTags } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { useBlogContext } from '../../../hooks/contextHooks';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from './styled';

export interface IDropdownProps {
  gridC?: string;
  gridR?: string;
  tabletGridC?: string;
  tabletGridR?: string;
}

const Dropdown = (props: React.PropsWithChildren<IDropdownProps>) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const { blogTags } = useBlogContext();
  const location = useLocation();
  return (
    <DropDownContainer
      gridC={props.gridC}
      gridR={props.gridR}
      tabletGridC={props.tabletGridC}
      tabletGridR={props.tabletGridR}
    >
      <h1>Filter posts: </h1>
      <DropDownHeader onClick={toggling}>All Posts</DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {blogTags.map((tag, index) => {
              return (
                <ListItem key={index}>
                  <AiFillTags />
                  <Link
                    to={`/blog/filtered/${tag}`}
                    state={{ from: location.pathname }}
                    onClick={toggling}
                  >
                    {tag}
                  </Link>
                </ListItem>
              );
            })}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default Dropdown;

import { Linkedin } from "@styled-icons/boxicons-logos";
import React, { useContext } from "react";
import { useThemeContext } from "../../hooks/contextHooks";
import {
  CardContent,
  CardDot,
  CardDots,
  CardFooter,
  CardFooterExternalLink,
  CardFooterInternalLink,
  CardHeader,
  CardList,
  CardWrapper,
} from "./styled";

export interface ICardProps {
  gridC?: string;
  gridR?: string;
  tabletGridC?: string;
  tabletGridR?: string;
  links?: {
    Socials?: Array<{ name: string; link: string }>;
    Left?: { name: string; link: string; type: string };
    Right?: { name: string; link: string; type: string };
  };
  title?: string;
  background?: string;
  themeType?: string;
}
const Card = (props: React.PropsWithChildren<ICardProps>) => {
  const { themeType } = useThemeContext();
  return (
    <CardWrapper
      gridC={props.gridC}
      gridR={props.gridR}
      tabletGridC={props.tabletGridC}
      tabletGridR={props.tabletGridR}
      themeType={themeType}
    >
      <CardHeader>
        <CardDots>
          <CardDot></CardDot>
          <CardDot></CardDot>
          <CardDot></CardDot>
        </CardDots>
        {props.title}
      </CardHeader>
      <CardContent background={props.background}>
        {props.links && props.links.Socials && props.links.Socials.length > 0 && (
          <>
            <p>
              You can find me on the following{" "}
              {props.title?.indexOf("Techy") !== -1 ? "techy" : ""} social
              networks:
            </p>
            <CardList>
              {props.links.Socials.map((social, index) => {
                return (
                  <li key={index}>
                    <a href={social.link} rel="Left">
                      {social.name}
                    </a>
                  </li>
                );
              })}
            </CardList>
          </>
        )}
        {props.children}
      </CardContent>
      <CardFooter>
        {props.links &&
          props.links?.Left &&
          (props.links.Left?.type === "external" ? (
            <CardFooterExternalLink
              rel="Left noopener noreferrer"
              href={props.links.Left.link}
            >
              {props.links.Left.name}
            </CardFooterExternalLink>
          ) : (
            <CardFooterInternalLink to={`/blog/${props.links.Left.link}`}>
              {props.links.Left.name}
            </CardFooterInternalLink>
          ))}
        {props.links &&
          props.links?.Right &&
          (props.links.Right?.type === "external" ? (
            <CardFooterExternalLink
              rel="Left noopener noreferrer"
              href={props.links.Right.link}
            >
              {props.links.Right.name}
            </CardFooterExternalLink>
          ) : (
            <CardFooterInternalLink to={`/blog/${props.links.Right.link}`}>
              {props.links.Right.name}
            </CardFooterInternalLink>
          ))}
      </CardFooter>
    </CardWrapper>
  );
};

export default Card;

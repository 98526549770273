import styled from "styled-components";
import { desktopMin } from "../../constants/constants";
import { H3, H4 } from "../../style/styled";

const LoginFormContainer = styled.div`
  width: 100%;
  height: 75vh;
  background: var(--secondaryBackground);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  @media screen and ${desktopMin} {
    flex-direction: row;
  }
`;
const LoginFormLeft = styled.div`
  color: var(--CTAText);
  width: 100%;
  order: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  @media screen and ${desktopMin} {
    width: 50%;
    height: 100%;
  }
`;

const LoginFormLeftHeader = styled(H3)`
  color: var(--CTAText);
  margin-bottom: 15px;
`;

const LoginFormLeftContent = styled.p`
  align-self: flex-start;
`;

const LoginFormLeftContentList = styled.ul`
  margin-left: 23px;
  margin-top: 15px;
`;

const LoginFormLeftContentListItem = styled.li``;

const LoginFormRight = styled.div`
  width: 100%;
  order: 2;
  background: var(--background);
  border: 1px solid var(--secondaryBackground);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  @media screen and ${desktopMin} {
    width: 50%;
    height: 100%;
    border-radius: 0 10px 10px 0;
  }
`;

const LoginFormRightHeader = styled(H3)`
  color: var(--mainText);
`;

const LoginFormForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
`;

const LoginFormFormGroup = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0 0 0;
`;

const LoginFormFormGroupWrapperHalf = styled.div`
  &:first-child {
    margin-right: 25px;
  }
  width: 50%;
`;
const LoginFormFormGroupWrapperFull = styled.div`
  width: 100%;
`;

const LoginFormFormGroupWrapperLabel = styled.label`
  font-size: 16px;
  margin-bottom: 9px;
  display: block;
`;

const LoginFormFormGroupWrapperTextInput = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border: 1px solid var(--secondaryBackground);
  border-radius: 10px;
  background: none;
  color: var(--mainText);
`;

const LoginFormDivider = styled.hr`
  width: 90%;
  margin: 15px 0;
`;

const LoginFormSocialsHeader = styled(H4)`
  color: var(--secondaryBackground);
  border-radius: 50%;
  padding: 8px 10px;
`;

const LoginFormButton = styled.div`
  width: 100%;
  min-height: 40px;
  font-size: 16px;
  margin: 10px;
  padding: 5px;
  background-color: var(--CTABackground);
  color: var(--CTAText);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;
const LoginFormButtonText = styled.span`
  padding-left: 5px;
  text-align: center;
`;

export {
  LoginFormContainer,
  LoginFormLeft,
  LoginFormLeftHeader,
  LoginFormLeftContent,
  LoginFormLeftContentList,
  LoginFormLeftContentListItem,
  LoginFormRight,
  LoginFormRightHeader,
  LoginFormForm,
  LoginFormFormGroup,
  LoginFormFormGroupWrapperHalf,
  LoginFormFormGroupWrapperFull,
  LoginFormFormGroupWrapperLabel,
  LoginFormFormGroupWrapperTextInput,
  LoginFormDivider,
  LoginFormSocialsHeader,
  LoginFormButton,
  LoginFormButtonText,
};
